import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const featuredSlice=createSlice({
    name: "featured",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setfeatured(state,action){
            state.data=action.payload
        },
        getfeatured(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setfeatured,setStatus,getfeatured}=featuredSlice.actions
export default featuredSlice.reducer




// thunk

export function fetchfeatured(){
    return async function fetchfeaturedThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/featureDeal/get`).then(
                
                (resp) => {
                    console.log(resp.data)
                    dispatch(setfeatured(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createfeatured(param){
    return async function createfeaturedThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/featureDeal/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("featured Created")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatefeatured(id){
    return async function updatefeaturedThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/featureDeal/update/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("featured Updated")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletefeatured(id){
    return async function deletefeaturedThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/featureDeal/delete/${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdfeatured(id){
    return async function getByIdfeaturedThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/featureDeal/get/${id}`).then(
                (resp) => {
                    dispatch(getfeatured(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
