import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const bannerSlice=createSlice({
    name: "banner",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setBanner(state,action){
            state.data=action.payload
        },
        getBanner(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setBanner,setStatus,getBanner}=bannerSlice.actions
export default bannerSlice.reducer




// thunk

export function fetchBanner(){
    return async function fetchBannerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`http://5.161.110.71:8000/ecommerce/users/get`).then(
                (resp) => {
                    dispatch(setBanner(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}

export function fetchBannerfalse(){
    return async function fetchBannerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`https://foodserver.ashhkaro.com/common/rider/
`).then(
                (resp) => {
                    dispatch(setBanner(resp?.data))
                    console.log(resp?.data)
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
export function fetchdeals(){
    return async function fetchBannerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`https://foodserver.ashhkaro.com/common/restaurant/getAllRestaurant
`).then(
                (resp) => {
                    dispatch(setBanner(resp?.data))
                    console.log(resp?.data)
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createBanner(param){
    return async function createBannerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/banner/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("Banner Created")
                    const base_url = window.location.origin;
                    window.location.href=base_url+"/#/banners";
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateBanner(id,param){
    return async function updateBannerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/banner/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("Banner Updated")
                    const base_url = window.location.origin;
                    window.location.href=base_url+"/#/banners";
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteBanner(id){
    return async function deleteBannerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`http://5.161.110.71:5000/common/business/delBusinesses/${id}`).then(res=>{
                console.log(res.data)
                if(res.data){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            message.error('SOmething went wrong')
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdBanner(id){
    return async function getByIdBannerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/banner/get/${id}`).then(
                (resp) => {
                    dispatch(getBanner(resp.data.data))
                    console.log(resp.data,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy getbyid Api")
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
