import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import Loader from '../Loader';
import { message } from 'antd';

const FeaturedBoughtDisplay = () => {
    const [featuredBought, setFeaturedBought] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFeaturedBought = async () => {
            try {
                const response = await axios.get('https://foodserver.ashhkaro.com/common/featuredBought');
                console.log(response.data)
                setFeaturedBought(response.data); // Adjust based on actual data structure
                message.success('Featured items fetched successfully!');
            } catch (error) {
                console.error('Error fetching featured items:', error);
                message.error('Failed to fetch featured items.');
            } finally {
                setLoading(false);
            }
        };

        fetchFeaturedBought();
    }, []);

    // Initialize DataTable
    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#featuredBoughtTable').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    destroy: true,
                });
            }, 1000);
        });
    }, [featuredBought]);

    return (
        <div className='mt-5 toppp'>
            {loading ? <Loader /> : null}
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        Featured Bought Items
                    </h2>
                </div>
                <div className="row" id="featured-bought-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-6 mb-2 mb-md-0">
                                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                                            Featured Payments
                                            <span className="badge badge-soft-dark radius-50 fz-12">{featuredBought.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {featuredBought.length > 0 ? (
                                <div className="table-responsive">
                                    <table
                                        id="featuredBoughtTable"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th className="pl-xl-5">SL</th>
                                                <th>Item Name</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {featuredBought.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td className="pl-xl-5">{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.cost}</td>
                                                    <td>
                                                        <i className={`mdi mdi-checkbox-blank-circle me-1 `}></i>
                                                        {item.status}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className='text-center'>No featured items available</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturedBoughtDisplay;
