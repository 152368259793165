import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import Loader from '../Loader';

const Orders = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState({});

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('https://foodserver.ashhkaro.com/common/orders/');
                setOrders(response.data); // Adjust based on actual data structure
                message.success('Orders fetched successfully!');
            } catch (error) {
                console.error('Error fetching orders:', error);
                message.error('Failed to fetch orders.');
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    // Initialize DataTable
    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#ordersTable').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    destroy: true,
                });
            }, 1000);
        });
    }, [orders]);

    const toggleDropdown = (id) => {
        setDropdownOpen((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleStatusChange = async (orderId, newStatus) => {
        console.log(`Changing status of order ${orderId} to ${newStatus}`);
        // You might want to make an API call here to update the order status
        toggleDropdown(orderId);
    };

    return (
        <div className='mt-5 toppp'>
            {loading ? <Loader /> : null}
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        Orders
                    </h2>
                </div>
                <div className="row" id="order-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-6 mb-2 mb-md-0">
                                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                                            Order List
                                            <span className="badge badge-soft-dark radius-50 fz-12">{orders.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {orders.length > 0 ? (
                                <div className="table-responsive">
                                    <table
                                        id="ordersTable"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th className="pl-xl-5">Select</th>
                                                <th>Item Name</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Invoice ID</th>
                                                <th>Total Price</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.map((order) =>
                                                order.items.map((item, index) => (
                                                    <tr key={`${order._id}-${index}`}>
                                                        <td>
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={order._id}
                                                                />
                                                                <label className="form-check-label" htmlFor={order._id}></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="avatar-xs">
                                                                <span>{item.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.price.toFixed(2)}</td>
                                                        <td>
                                                            {order.invoiceId}
                                                            <p style={{ fontSize: '10px' }}>{order.paymentType}</p>
                                                        </td>
                                                        <td>{order.totalPrice.toFixed(2)}</td>
                                                        <td>
                                                            <i className={`mdi mdi-checkbox-blank-circle me-1`}></i>
                                                            {order.status}
                                                        </td>
                                                        <td>
                                                        <td>
                                                        <div className="d-flex gap-3 align-items-center">
                                                          
                                                            {/* <button
                                                                onClick={() => navigate(`/orderdetail/${order._id}`)} // Adjust navigation as needed
                                                                className="btn btn-outline-primary square-btn btn-sm"
                                                                title="View"
                                                            >
                                                                <i className="bi bi-eye" />
                                                            </button> */}
                                                        </div>
                                                    </td>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className='text-center'>No orders available</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Orders;
