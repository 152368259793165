import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const smsGatewaySlice=createSlice({
    name: "smsGateway",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setsmsGateway(state,action){
            state.data=action.payload
        },
        getsmsGateway(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setsmsGateway,setStatus,getsmsGateway}=smsGatewaySlice.actions
export default smsGatewaySlice.reducer




// thunk

export function fetchsmsGateway(){
    return async function fetchsmsGatewayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/smsGateway`).then(
                (resp) => {
                    dispatch(setsmsGateway(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createsmsGateway(param){
    return async function createsmsGatewayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/smsGateway/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("smsGateway Created")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatesmsGateway(id){
    return async function updatesmsGatewayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/smsGateway/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("smsGateway Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletesmsGateway(id){
    return async function deletesmsGatewayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/smsGateway/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdsmsGateway(id){
    return async function getByIdsmsGatewayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/smsGateway/${id}`).then(
                (resp) => {
                    dispatch(getsmsGateway(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
