import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const shippingChargesSlice=createSlice({
    name: "shippingCharges",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setshippingCharges(state,action){
            state.data=action.payload
        },
        getshippingCharges(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setshippingCharges,setStatus,getshippingCharges}=shippingChargesSlice.actions
export default shippingChargesSlice.reducer




// thunk

export function fetchshippingCharges(){
    return async function fetchshippingChargesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/shippingCharges`).then(
                (resp) => {
                    dispatch(setshippingCharges(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createshippingCharges(param){
    return async function createshippingChargesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/shippingCharges/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("shippingCharges Created")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateshippingCharges(id){
    return async function updateshippingChargesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/shippingCharges/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("shippingCharges Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteshippingCharges(id){
    return async function deleteshippingChargesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/shippingCharges/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdshippingCharges(id){
    return async function getByIdshippingChargesThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/shippingCharges/${id}`).then(
                (resp) => {
                    dispatch(getshippingCharges(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
