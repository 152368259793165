import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const sellerSlice=createSlice({
    name: "seller",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setseller(state,action){
            state.data=action.payload
        },
        getseller(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setseller,setStatus,getseller}=sellerSlice.actions
export default sellerSlice.reducer




// thunk

export function fetchseller(){
    return async function fetchsellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/seller/get`).then(
                (resp) => {
                    dispatch(setseller(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createseller(param){
    return async function createsellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/seller/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("seller Created")
                    const base_url = window.location.origin;
                    window.location.href=base_url+"/#/sellerList";
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateseller(id,param){
    return async function updatesellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/seller/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("seller Updated")
                    const base_url = window.location.origin;
                    window.location.href=base_url+"/#/sellerlist";
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteseller(id){
    return async function deletesellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/seller/delete/${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdseller(id){
    return async function getByIdsellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/seller/get/${id}`).then(
                (resp) => {
                    dispatch(getseller(resp.data.data))
                    console.log(resp.data,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy getbyid Api")
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
