import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const featureDealProductSlice=createSlice({
    name: "featureDealProduct",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setfeatureDealProduct(state,action){
            state.data=action.payload
        },
        getflash(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setfeatureDealProduct,setStatus,getfeatureDealProduct}=featureDealProductSlice.actions
export default featureDealProductSlice.reducer




// thunk

export function fetchflash(){
    return async function fetchflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/featureDealProduct/get`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(setfeatureDealProduct(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createflash(param){
    return async function createflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/featureDealProduct/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("Feature deal product created")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateflash(id,param){
    return async function updateflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/featureDealProduct/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("flash Updated")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteflash(id){
    return async function deleteflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/featureDealProduct/delete/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdflash(id){
    return async function getByIdflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/featureDealProduct/get/${id}`).then(
                (resp) => {
                    dispatch(setfeatureDealProduct(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}

export function getSingByIdflash(id){
    return async function getByIdflashThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/featureDealProduct/getS/${id}`).then(
                (resp) => {
                    dispatch(setfeatureDealProduct(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
