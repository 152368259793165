import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader'; // Assuming you have a Loader component for loading states


const RestaurantCostFeeForm = () => {
    const [cost, setCost] = useState('');
    const [loading, setLoading] = useState(false);
    const [restaurantFees, setRestaurantFees] = useState([]); // Holds all restaurant fee data
    const navigate = useNavigate();

    // Fetch restaurant fee data on component mount
    useEffect(() => {
        const fetchFees = async () => {
            try {
                const response = await axios.get('https://foodserver.ashhkaro.com/common/restaurantFee');
                setRestaurantFees(response.data); // Assume API returns an array of fees
            } catch (error) {
                console.error('Error fetching restaurant fees:', error);
                message.error('Failed to load restaurant fees.');
            }
        };

        fetchFees();
    }, []);

    // Handle form submission (creating or updating cost)
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate cost input
        if (!cost || isNaN(cost) || cost <= 0) {
            message.error('Please enter a valid cost greater than 0');
            return;
        }

        try {
            setLoading(true);

            // If cost is new, create new entry, otherwise update existing one
            if (restaurantFees.some(fee => fee.cost === cost)) {
                message.warning('This cost already exists.');
            } else {
                const response = await axios.post('https://foodserver.ashhkaro.com/common/restaurantFee', { cost });
                setRestaurantFees((prev) => [...prev, response.data]); // Add the new fee to the list
                message.success('Restaurant fee saved successfully!');
            }

            setCost('');
        } catch (error) {
            message.error('An error occurred. Please try again.');
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle delete functionality
    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this fee?',
            onOk: async () => {
                try {
                    await axios.delete(`https://foodserver.ashhkaro.com/common/restaurantFee/${id}`);
                    setRestaurantFees((prev) => prev.filter((fee) => fee._id !== id)); 
                    message.success('Restaurant fee deleted successfully!');
                } catch (error) {
                    console.error('Delete error:', error);
                    message.error('Failed to delete restaurant fee.');
                }
            },
        });
    };

    const handleUpdate = (id) => {
        navigate(`/updateRestaurantFee/${id}`); 
    };

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0 d-flex gap-10">
                    <img
                        src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                        alt="Brand logo"
                    />
                    Restaurant Fee Setup
                </h2>

                {restaurantFees.length === 0 && (
                    <form onSubmit={handleSubmit}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group lang_form" id="cost-form">
                                        <label className="title-color">
                                            Restaurant Fee Cost<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            name="cost"
                                            id="cost"
                                            className="form-control"
                                            placeholder="Enter fee cost"
                                            required=""
                                            value={cost}
                                            onChange={(e) => setCost(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Submit and Reset buttons */}
                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {loading ? <Loader /> : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </form>
                )}

                {restaurantFees.length > 0 && (
                    <div className="row mt-3" id="cate-table">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="text-capitalize d-flex gap-1">
                                                Restaurant Fee List
                                                <span className="badge badge-soft-dark radius-50">{restaurantFees.length}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Fee Cost</th>
                                                <th>Created At</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {restaurantFees.length > 0 ? (
                                                restaurantFees.map((item, index) => (
                                                    <tr key={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.cost}</td>
                                                        <td>{item.createdAt}</td>
                                                        <td className="text-center">
                                                            <div className="d-flex justify-content-center gap-2">
                                                                <button
                                                                    className="btn btn-outline-success square-btn btn-sm mr-1"
                                                                    onClick={() => handleUpdate(item._id)}
                                                                >
                                                                    Edit
                                                                </button>
                                                                {/* <button
                                                                    onClick={() => handleDelete(item._id)}
                                                                    className="btn btn-outline-danger square-btn btn-sm mr-1"
                                                                    title="Delete"
                                                                >
                                                                    Delete
                                                                </button> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="4" className="text-center">No data available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RestaurantCostFeeForm;
