import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const withdrawSlice=createSlice({
    name: "withdraw",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setwithdraw(state,action){
            state.data=action.payload
        },
        getwithdraw(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setwithdraw,setStatus,getwithdraw}=withdrawSlice.actions
export default withdrawSlice.reducer

// thunk

export function fetchwithdraw(){
    return async function fetchwithdrawThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/withdraw/get`).then(
                (resp) => {
                    dispatch(setwithdraw(resp?.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createwithdraw(param){
    
    return async function createwithdrawThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/seller/withdraw/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("withdraw Created")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatewithdraw(id,params){
    return async function updatewithdrawThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/withdraw/update/${id}`,params).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("withdraw Updated")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletewithdraw(id){
    return async function deletewithdrawThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/withdraw/delete/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdwithdraw(id){

    return async function getByIdwithdrawThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/seller/withdraw/${id}`).then(
                (resp) => {
                    dispatch(getwithdraw(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
