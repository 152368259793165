import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { deletefood, fetchfood, updateproduct } from '../../store/productSlice';

const Food = () => {
    const dispatch = useDispatch();
    const { data: product } = useSelector((state) => state?.product);
    const [checkedStates, setCheckedStates] = useState([]);

    // Fetch product data when component mounts
    useEffect(() => {
        dispatch(fetchfood());
    }, [dispatch]);

    // Update checked states when product data changes
    useEffect(() => {
        if (product && product.length > 0) {
            const initialCheckedStates = product.map(item => item.status);
            setCheckedStates(initialCheckedStates);
        }
    }, [product]);

    // Initialize DataTable after the table data is available
    useEffect(() => {
        if (product && product.length > 0) {
            // Destroy any existing DataTable instance to avoid duplicate tables
            if ($.fn.dataTable.isDataTable('#allOrder')) {
                $('#allOrder').DataTable().destroy();
            }

            // Re-initialize DataTable
            $('#allOrder').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                bDestroy: true, // Ensure table is destroyed and recreated each time
            });
        }

        // Cleanup DataTable when the component is unmounted
        return () => {
            if ($.fn.dataTable.isDataTable('#allOrder')) {
                $('#allOrder').DataTable().destroy();
            }
        };
    }, [product]);

    // Handle product deletion
    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletefood(id));
            }
        });
    };

    // Handle status toggle
    const toggleStatus = (index, id) => {
        const newStatus = !checkedStates[index];
        setCheckedStates((prevStates) => {
            const updatedStates = [...prevStates];
            updatedStates[index] = newStatus;
            return updatedStates;
        });

        const param = new FormData();
        param.append('status', newStatus);
        dispatch(updateproduct(id, param));
    };

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px' }}>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse-product-list.png"
                            alt=""
                        />
                        Food
                        <span className="badge badge-soft-dark radius-50 fz-14 ml-1">2</span>
                    </h2>
                </div>
                <div className="row mt-20">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table
                                        id="allOrder"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th>Service Name</th>
                                                <th>Image</th>
                                                <th>Payment Status</th>
                                                <th>Discount</th>
                                                <th>Status</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product?.length > 0 ? product?.map((item, index) => (
                                                <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.subcategoryId?.name}</td>
                                                    <td>
                                                        <img
                                                            src={item?.foodPic}
                                                            className="avatar border"
                                                            alt=""
                                                            style={{ width: '50px', height: '50px' }} // Inline styling for the image
                                                        />
                                                    </td>
                                                    <td>{item?.paymentStatus ? 'True' : 'False'}</td>
                                                    <td>{item?.discountPercentage}</td>
                                                    <td>
                                                        <div style={{
                                                            position: 'relative',
                                                            display: 'inline-block',
                                                            width: '40px',
                                                            height: '20px',
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedStates[index]}
                                                                onChange={() => toggleStatus(index, item._id)}
                                                                style={{
                                                                    opacity: 0,
                                                                    width: 0,
                                                                    height: 0,
                                                                }}
                                                            />
                                                            <div
                                                                onClick={() => toggleStatus(index, item._id)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    backgroundColor: checkedStates[index] ? '#2196F3' : '#ccc',
                                                                    transition: '.4s',
                                                                    borderRadius: '20px',
                                                                }}
                                                            >
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    content: '""',
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    left: checkedStates[index] ? '22px' : '2px',
                                                                    bottom: '2px',
                                                                    backgroundColor: 'white',
                                                                    transition: '.4s',
                                                                    borderRadius: '50%',
                                                                }}></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <button
                                                                onClick={() => onDelete(item._id)}
                                                                className="btn btn-outline-danger square-btn btn-sm mr-1"
                                                                title="Delete"
                                                            >
                                                                <i className="bi bi-trash" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">No products available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Food;
