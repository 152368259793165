import React,{useEffect} from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { deleteseller, fetchseller, updateseller } from '../../store/sellerSlice';
import axios from 'axios';
import { api_url } from '../../ApiUrl';
import { useHistory,useNavigate } from 'react-router-dom';

const SellerList = () => {
    const navigate = useNavigate();
    
    const dispatch = useDispatch()
    const { data: seller, status } = useSelector((state) => state?.seller)


    console.log(seller,'===============seller')

    useEffect(() => {
        dispatch(fetchseller())
    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteseller(id))
            }

        })
    }



    const approveSeller = (id,status) => {
        Modal.confirm({
            title: 'Are you sure you want to approve?',
            onOk: () => {

                const param=new FormData();
                param.append('status', status);
                

                dispatch(updateseller(id,param))
            }

        })
    }



    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-4">
                    <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/add-new-seller.png"
                            alt=""
                        />
                        Seller list
                        <span className="badge badge-soft-dark radius-50 fz-12">10</span>
                    </h2>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="table-responsive">
                                <table
                                id="allOrder"
                                    style={{ textAlign: "left" }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL</th>
                                            <th>Shop name</th>
                                            <th>Seller name</th>
                                            <th>Contact info</th>
                                            <th>Status</th>
                                            <th className="text-center">Total products</th>
                                            <th className="text-center">Total orders</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {seller?.length>0?seller && seller?.map((i, index) => {
                                            return (<>
                                    <tr>
                                            <td>{index+1}</td>
                                            <td>
                                                <div className="d-flex align-items-center gap-10 w-max-content flex-wrap">
                                                    <img
                                                        width='35px'
                                                        height='35px'
                                                        src={i?.logo}
                                                        className='rounded-3'
                                                    />
                                                    <div>
                                                        <a
                                                            className="title-color"
                                                        >
                                                            {i.shopName?.slice(0,5)}...
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <a
                                                    title="View"
                                                    className="title-color"
                                                >
                                                    {i.firstName+' '+i.lastName}
                                                </a>
                                            </td>
                                            <td>
                                                <div className="mb-1">
                                                    <strong>
                                                        <a
                                                            className="title-color hover-c1"
                                                            href={`mailto:${i?.email}`}
                                                        >
                                                            {i?.email}
                                                        </a>
                                                    </strong>
                                                </div>
                                                <a className="title-color hover-c1" href={`tel:${i?.phone}`}>
                                                    {i?.phone}
                                                </a>
                                            </td>
                                            <td>
                                                {i?.status===false?<label className="badge badge-danger bg-danger ">Pending</label>:<label className="badge badge-success">Active</label>}
                                            </td>
                                            <td className="text-center">
                                                <a
                                                    className="btn text--primary bg-soft--primary font-weight-bold px-3 py-1 mb-0 fz-12"
                                                >
                                                    {i?.product.length}
                                                </a>
                                            </td>
                                            <td className="text-center">
                                                <a
                                                    className="btn text--info bg-soft-info font-weight-bold px-3 py-1 fz-12 mb-0"
                                                >
                                                    {i.saleProduct.length}
                                                </a>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center gap-2 flex-wrap">
                                                <button
                                                               className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                      onClick={()=> navigate('/UpdateSeller/'+i?.id)}
                                                                                  >
                                                                 <i className="bi bi-pencil" />
                                                           </button>
                                                    <button
                                                        title="Delete"
                                                        className="btn btn-outline-danger btn-sm square-btn"
                                                        onClick={()=>onDelete(i?.id)}
                                                    >
                                                        <i className="bi bi-trash" />
                                                    </button>
                                                    <button
                                                        title="Approve"
                                                        className="btn btn-outline-dark btn-sm square-btn"
                                                        onClick={()=>approveSeller(i?.id,i?.status===false?true:false)}
                                                    >
                                                        <i className="bi bi-exclamation-circle" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        </>)
                                        }):''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SellerList
