import React from "react";
import { Chart } from "react-google-charts";


const DomChart = () => {

    const data = [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7],
      ];


      const options = {
        title: "My Daily Activities",
        hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0 },
        chartArea: { width: "90%", height: "90%" },
      };

  return (
    <div>
        <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"400px"}
    />
      
    </div>
  )
}

export default DomChart
