import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const sellerSaleSlice=createSlice({
    name: "sellerSale",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setsellerSale(state,action){
            state.data=action.payload
        },
        getsellerSale(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setsellerSale,setStatus,getsellerSale}=sellerSaleSlice.actions
export default sellerSaleSlice.reducer




// thunk

export function fetchsellerSale(){
    return async function fetchsellerSaleThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/sellerSale`).then(
                (resp) => {
                    dispatch(setsellerSale(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createsellerSale(param){
    return async function createsellerSaleThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/sellerSale/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "success") {
                    message.success("sellerSale Created")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatesellerSale(id){
    return async function updatesellerSaleThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/sellerSale/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("sellerSale Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletesellerSale(id){
    return async function deletesellerSaleThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/sellerSale/${id}`).then(res=>{
                console.log(res.data)
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdsellerSale(id){
    return async function getByIdsellerSaleThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/sellerSale/${id}`).then(
                (resp) => {
                    dispatch(getsellerSale(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
