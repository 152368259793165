import React, { useEffect, useState } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';
import { deletebrand } from '../../store/brandSlice';
import axios from 'axios';
import { api_url } from '../../ApiUrl';
import { useHistory,useNavigate } from 'react-router-dom';

const CustomerList = () => {
    const navigate = useNavigate();
    const [customer,setCustomer]=useState()

    useEffect(()=>{
        axios.get(`${api_url}/users/get`).then((res)=>{
            setCustomer(res.data.data)
          })
    },[])

    $(document).ready(function () {
        setTimeout(function () {
            $('#customerList').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                axios.delete(`${api_url}/users/delete/${id}`).then((res)=>{
                    if(res.data.status === 'ok'){
                        message.success('Deleted successfully')
                    }
                  })
            }

        })
    }





    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-4">
                    <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/customer.png"
                            alt=""
                        />
                        Customer list
                        <span className="badge badge-soft-dark radius-50">8</span>
                    </h2>
                </div>
                <div className="card p-4">
                    <div className="table-responsive datatable-custom">
                        <table
                            style={{ textAlign: "left" }}
                            className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                            id='customerList'
                        >
                            <thead className="thead-light thead-50 text-capitalize">
                                <tr>
                                    <th>SL</th>
                                    <th>Customer name</th>
                                    <th>Contact info</th>
                                    <th>Total Order </th>
                                 
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customer?.length>0?customer && customer?.map((i, index) => {
                                    return (<>
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>
                                                <a
                                                    className="title-color hover-c1 d-flex align-items-center gap-10"
                                                >
                                                    <img
                                                        src={i?.image?i?.image:"https://6valley.6amtech.com/storage/app/public/profile/2022-10-12-63464cd299fc3.png"}
                                                        className="rounded-circle"
                                                        alt=""
                                                        width={30}
                                                        height={30}
                                                    />
                                                    {i?.firstName+' '+i?.lastName}
                                                </a>
                                            </td>
                                            <td>
                                                <div className="mb-1">
                                                    <strong>
                                                        <a
                                                            className="title-color hover-c1"
                                                            href={`mailto:${i?.email}`}
                                                        >
                                                            {i?.email}
                                                        </a>
                                                    </strong>
                                                </div>
                                                <a className="title-color hover-c1" href={`tel:${i?.phoneNumber}`}>
                                                {i?.phoneNumber}
                                                </a>
                                            </td>
                                            <td>
                                                <label className="btn text-info bg-soft-info font-weight-bold px-3 py-1 mb-0 fz-12">
                                                    {i?.sale?.length}
                                                </label>
                                            </td>
                                            {/* <td>
                                                <label className="switcher">
                                                    <input
                                                        type="checkbox"
                                                        className="switcher_input"
                                                        id={9}
                                                        defaultChecked=""
                                                    />
                                                    <span className="switcher_control" />
                                                </label>
                                            </td> */}
                                            <td>
                                                <div className="d-flex justify-content-center gap-2">
                                                
                                                    <button
                                                    onClick={(_id)=>onDelete(_id)}
                                                        title="Delete"
                                                        className="btn btn-outline-danger btn-sm delete square-btn"
                                                    >
                                                        <i className="bi bi-trash" />
                                                    </button>
                                                    <button
                                                   onClick={()=> navigate('/CustomerWallet/'+i?.id)}
                                                        title="wallet"
                                                        className="btn btn-outline-success btn-sm delete square-btn"
                                                    >
                                                        <i className="bi bi-wallet" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>

                                    </>)
                                }):''}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CustomerList
