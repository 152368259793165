import React from 'react'

const Navbar = ({ Toggle }) => {
  return (
    <div>
      <header className="top-header">
        <nav className="navbar navbar-expand gap-3">
          <div className="mobile-toggle-icon fs-3">
            <i className="bi bi-list" onClick={Toggle} style={{ cursor: 'pointer' }} />
          </div>
          <form className="searchbar">
            <div className="position-absolute top-50 translate-middle-y search-icon ms-3">
              <i className="bi bi-search" />
            </div>
            <input
              className="form-control"
              type="text"
              placeholder="Type here to search"
            />
            <div className="position-absolute top-50 translate-middle-y search-close-icon">
              <i className="bi bi-x-lg" />
            </div>
          </form>
        </nav>
      </header>


    </div>
  )
}

export default Navbar
