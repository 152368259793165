import {configureStore} from '@reduxjs/toolkit';
import bannerReducer from './bannerSlice'
import brandReducer from './brandSlice'
import allOrderReducer from './allOrderSlice'
import categoryReducer from './categorySlice'
import featuredReducer from './featuredSlice'
import flashReducer from './flashSlice'
import dealDayReducer from './dealDaySlice'
import subSubCategoryReducer from './subSubCategorySlice'
import subCategoryReducer from './subCategorySlice'
import productReducer from './productSlice'
import sellerReducer from './sellerSlice'
import sellerSaleReducer from './sellerSaleSlice'
import smsGatewayReducer from './smsGatewaySlice'
import shippingChargesReducer from './shippingChargesSlice'
import flashDealProductReducer from './flashDealProductSlice'
import featureDealProductReducer from './featureDealProductSlice'
import withdrawReducer from './withdrawSlice'



const Store=configureStore({
    reducer:{
        banner:bannerReducer,
        withdraw:withdrawReducer,
        brand:brandReducer,
        category:categoryReducer,
        subSubCategory:subSubCategoryReducer,
        subCategory:subCategoryReducer,
        allOrder:allOrderReducer,
        featured:featuredReducer,
        flash:flashReducer,
        flashDealProduct:flashDealProductReducer,
        dealDay:dealDayReducer,
        product:productReducer,
        seller:sellerReducer,
        sellerSale:sellerSaleReducer,
        smsGateway:smsGatewayReducer,
        shippingCharges:shippingChargesReducer,
        featureDealProduct:featureDealProductReducer,
    }
})



export default Store