import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const brandSlice=createSlice({
    name: "brand",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setbrand(state,action){
            state.data=action.payload
        },
        getbrand(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setbrand,setStatus,getbrand}=brandSlice.actions
export default brandSlice.reducer




// thunk

export function fetchbrand(){
    return async function fetchbrandThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/brand/get`).then(
                
                (resp) => {
                    dispatch(setbrand(resp.data.data))
                    console.log(resp.data)
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createbrand(param){
    return async function createbrandThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/brand/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("brand Created")
                    const base_url = window.location.origin;
                    window.location.href=base_url+"/#/brand";
                }
                else{
                    message.error(res.data.message)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatebrand(id,param){
    return async function updatebrandThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/brand/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("brand Updated")
                    const base_url = window.location.origin;
                    window.location.href=base_url+"/#/brand";
                    window.location.reload(true)
                    console.log("===================================updateeeeeeeeeee")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletebrand(id){
    return async function deletebrandThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/brand/delete/${id}`).then(res=>{
                console.log(res.data)
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdbrand(id){
    return async function getByIdbrandThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/brand/get/${id}`).then(
                (resp) => {
                    dispatch(getbrand(resp.data.data))
                    console.log(resp.data,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy getbyid Api")
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
