import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import Loader from '../Loader';
import { fetchsubCategory, createsubCategory, deletesubCategory, updatesubCategoryStatus } from '../../store/subCategorySlice';
import { fetchfeatured,getByIdcategory } from '../../store/categorySlice';
import axios from 'axios';
const SubCategory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data_sub } = useSelector((state) => state);
    const { data: category } = useSelector((state) => state.category);
    
    const [categoryid, setCategoryid] = useState('');
    const [sub_Category, setSub_Category] = useState('');
    const [loading, setLoading] = useState(false);
    const [checkedStates, setCheckedStates] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        dispatch(fetchfeatured());
       
        
    }, [dispatch]);

    useEffect(() => {
        // Initialize checkedStates based on subCategory status
        if (category && category.length > 0) {
            const initialCheckedStates = category.map((item) => item.status);
            setCheckedStates(initialCheckedStates);
        }
    }, [category]);

    // Initialize DataTable
    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#allOrder').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true
                });
            }, 1000);
        });
    }, []);
   

    const toggleSwitch = async (index, subcategoryId, currentStatus) => {
        const newStatus = !checkedStates[index]; // Toggle the current state
        console.log(subcategoryId, newStatus, currentStatus);
        try {
            // Update UI optimistically
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            // Update backend via Redux action
            await dispatch(updatesubCategoryStatus({ id: subcategoryId, status: newStatus }));

            // Handle success (if needed)
            console.log('Status updated successfully');
        } catch (error) {
            // Handle error (rollback UI changes if needed)
            console.error('Error updating status:', error);

            // Revert UI changes on failure
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });

            // Optionally show error message
            message.error('Failed to update category status.');
        }
    };

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletesubCategory(id));
            }
        });
    };


    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            {loading ? <Loader /> : null}
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                            alt=""
                        />
                       Featured
                    </h2>

                    <div className="row mt-3" id="cate-table">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="text-capitalize d-flex gap-1">
                                                Plans list
                                                <span className="badge badge-soft-dark radius-50 ">{category?.length}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                               
                                                <th>Price</th>
                                                <th>Date</th>

                                                <th>Status</th>
                                               
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {category?.length > 0 && category.map((sub, index) => (
                                                <tr key={sub._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{sub.name}</td>
                                                    <td>{sub.price}</td>
                                                    <td>{sub.createdAt}</td>
                                                    <td className="">
                                                        <div style={{
                                                            position: 'relative',
                                                            display: 'inline-block',
                                                            width: '40px',
                                                            height: '20px',
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedStates[index]}
                                                                onChange={() => toggleSwitch(index, sub._id, sub.status)}
                                                                style={{
                                                                    opacity: 0,
                                                                    width: 0,
                                                                    height: 0,
                                                                }}
                                                            />
                                                            <div
                                                                onClick={() => toggleSwitch(index, sub._id, sub.status)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    backgroundColor: checkedStates[index] ? '#2196F3' : '#ccc',
                                                                    transition: '.4s',
                                                                    borderRadius: '20px',
                                                                }}
                                                            >
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    content: '""',
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    left: checkedStates[index] ? '22px' : '2px',
                                                                    bottom: '2px',
                                                                    backgroundColor: 'white',
                                                                    transition: '.4s',
                                                                    borderRadius: '50%',
                                                                }}></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    
                                                    <td>
                                                        <div className="d-flex justify-content-center gap-2">
                                                            <button
                                                                className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                                onClick={() => navigate(`/UpdateSubCate/${sub._id}`)}
                                                            >
                                                                <i className="bi bi-pencil" />
                                                            </button>
                                                            <button
                                                                onClick={() => onDelete(sub._id)}
                                                                className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                                                title="Delete"
                                                            >
                                                                <i className="bi bi-trash" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubCategory;
