import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
// Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { updateflash,getByIdflash } from '../../store/flashSlice';
import { getSingByIdflash } from '../../store/flashDealProductSlice';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
const UpdateCoupon = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data: flash, } = useSelector((state) => state?.flash);
    console.log(flash)
    
    useEffect(()=>{
        dispatch( getByIdflash(id))
    },[id])
  

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                bDestroy: true,
            });
        }, 1000);
    });


    const handleCategory = (values) => {
       
    
            dispatch(updateflash(id,{code:values.code.value !=='' ?values.code.value:flash.code,discountPercentage:values.discountPercentage.value !==''?Number(values.discountPercentage.value):flash.discountPercentage,description:values.description.value !== ''?values.description.value:flash.description,status:values.status.value !== ''?values.status.value:flash.status,usageLimit:values.usageLimit.value !== ''?Number(values.usageLimit.value):flash.usageLimit,cost:values.cost.value !== ''? Number(values.cost.value):flash.cost,image:file !== null ?file :flash.image}));

    };

    const [file, setFile] = useState(null);

  
    const [randomCode, setRandomCode] = useState('');

    useEffect(() => {
        generateRandomCode();
    }, []);

    const generateRandomCode = () => {
        const length = 8;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setRandomCode(result);
    };

    const handleUpload = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const response = await axios.post('http://5.161.110.71:5000/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload is ${percentCompleted}% done.`);
                }
            });

            setFile(response.data.data);
            alert('Image uploaded successfully!');
        } catch (error) {
            console.error('Upload error:', error);
            alert('Failed to upload image.');
        }
    };
    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/flash_deal.png"
                            alt=""
                        />
                        Coupons
                    </h2>
                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className=" lang_form" id="en-form">
                                        <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                             Coupons code
                                            </label>
                                            <input
                                                type="text"
                                                name="code"
                                                className="form-control"
                                                id="code"
                                                placeholder="Ex : LUX"
                                                required=""
                                                defaultValue={flash.code}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                             Discount percentage
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="discountPercentage"
                                                name="discountPercentage"
                                                placeholder="discount %"
                                                required=""
                                                defaultValue={flash.discountPercentage}
                                               
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                            Cost
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cost"
                                                name="cost"
                                                placeholder="Cost"
                                                required=""
                                                defaultValue={flash.cost}
                                               
                                            />
                                        </div>
                                  
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                           Status
                                        </label>
                                        <select
                                            id="status"
                                            name="status"
                                            className="form-control"
                                            required=""
                                        >
                                            <option defaultValue={flash.status}>{flash.status}</option>
                                           {flash.status !== 'active' ? <option value="active">Active</option>:null}
                                           {flash.status !== 'inactive' ? <option value="inactive">Inactive</option>:null}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Description
                                        </label>
                                        <input
                                            type="text"
                                            name="description"
                                            className="form-control"
                                            id="description"
                                            placeholder="Enter description"
                                            required=""
                                            defaultValue={flash.description}
                                        />
                                    </div>
                                    <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                             Usage Limit
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="usageLimit"
                                                name="usageLimit"
                                                placeholder="Using Limit"
                                                required=""
                                                defaultValue={flash.usageLimit }
                                               
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                <div className="custom-file text-left">
                                <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Upload image
                                        </label>                                          {file? <img style={{display:'block'}} width={100} src={file} alt=''/>:<div>
                                          <img
                                                className="upload-img-view"
                                                id="viewer"
                                                src={flash.image}
                                                alt="image"
                                            />
                                            </div>}
                                        </div>
                                    <div style={{marginTop:'100px'}} className="form-group">
                                       
                                        <div className="custom-file text-left">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                className="custom-file-input"
                                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                onChange={(e)=>handleUpload(e)}
                                            />
                                            <label
                                                className="custom-file-label text-capitalize"
                                                htmlFor="customFileUpload"
                                            >
                                                Choose file
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                
                               
                            </div>
                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* Flash deal table */}
                    
                </div>
            </div>
        </div>
    );
};
export default UpdateCoupon;
