import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
// import Modals from '../Modals/Modals';
import UpdateCategorey from '../UpdateCategory/UpdateCategory';

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import {Modal, message } from 'antd';
import { createcategory, deletecategory, fetchcategory,updatecategorystatus ,getByIdcategory} from '../../store/categorySlice';
import Loader from '../Loader';


// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import axios from 'axios';
// import { Modal, message } from 'antd';
// import { fetchcategory, createcategory, deletecategory, updatecategory } from '../../store/categorySlice';
// import Loader from '../Loader';

const Category = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.category);
    const [file, setFile] = useState(null);
    const [categoryname, setCategoryname] = useState('');
    const [uploadStatus, setUploadStatus] = useState('');
    const [checkedStates, setCheckedStates] = useState([]);
    const [loading, setLoading] = useState(false);
console.log(data)
    useEffect(() => {
        dispatch(fetchcategory());
    }, [dispatch]);
  console.log(data)
    useEffect(() => {
        if (data && data.length > 0) {
            const initialCheckedStates = data.map((item) => item.status);
            setCheckedStates(initialCheckedStates);
        }
    }, [data]);

    const toggleSwitch = async (index, categoryId, currentStatus) => {
        const newStatus = !checkedStates[index]; // Toggle the current state
        console.log(categoryId,newStatus,currentStatus)
        try {
            // Update UI optimistically
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            // Update backend via Redux action
            await dispatch(updatecategorystatus({ id: categoryId, status: newStatus }));

            // Handle success (if needed)
            console.log('Status updated successfully');
        } catch (error) {
            // Handle error (rollback UI changes if needed)
            console.error('Error updating status:', error);

            // Revert UI changes on failure
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });

            // Optionally show error message
            message.error('Failed to update category status.');
        }
    };


    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: async () => {
                try {
                    await dispatch(deletecategory(id));
                    message.success('Category deleted successfully!');
                } catch (error) {
                    console.error('Delete error:', error);
                    message.error('Failed to delete category.');
                }
            }
        });
    };

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0 d-flex gap-10">
                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png" alt="" />
                    Category Setup
                </h2>

            

                <div className="row mt-3" id="cate-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                        <h5 className="text-capitalize d-flex gap-1">
                                            Category list
                                            <span className="badge badge-soft-dark radius-50 ">{data?.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table
                                    id='allOrder'
                                    style={{ textAlign: "left" }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL</th>
                                            <th className="text-center">Category Image</th>
                                            <th>Name</th>
                                            <th>Created At</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.length > 0 ? data.map((item, index) => (
                                            <tr key={item._id}>
                                                <td>{index + 1}</td>
                                                <td className="text-center">
                                                    <img
                                                        className="rounded"
                                                        width={64}
                                                        src={item.image}
                                                        alt={item.name}
                                                    />
                                                </td>
                                                <td>{item.name}</td>
                                                <td>{item.createdAt}</td>
                                                <td className="text-center">
                                                    <div style={{
                                                        position: 'relative',
                                                        display: 'inline-block',
                                                        width: '40px',
                                                        height: '20px',
                                                    }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkedStates[index]}
                                                            onChange={() => toggleSwitch(index, item._id, item.status)}
                                                            style={{
                                                                opacity: 0,
                                                                width: 0,
                                                                height: 0,
                                                            }}
                                                        />
                                                        <div
                                                            onClick={() => toggleSwitch(index, item._id, item.status)}
                                                            style={{
                                                                position: 'absolute',
                                                                cursor: 'pointer',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                backgroundColor: checkedStates[index] ? '#2196F3' : '#ccc',
                                                                transition: '.4s',
                                                                borderRadius: '20px',
                                                            }}
                                                        >
                                                            <div style={{
                                                                position: 'absolute',
                                                                content: '""',
                                                                height: '16px',
                                                                width: '16px',
                                                                left: checkedStates[index] ? '22px' : '2px',
                                                                bottom: '2px',
                                                                backgroundColor: 'white',
                                                                transition: '.4s',
                                                                borderRadius: '50%',
                                                            }}></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-center gap-2">
                                                        <button
                                                            className="btn btn-outline-success square-btn btn-sm mr-1"
                                                            onClick={() => navigate(`/UpdateCategorey/${item._id}`)}
                                                        >
                                                            <i className="bi bi-pencil" />
                                                        </button>
                                                        <button
                                                            onClick={() => handleDelete(item._id)}
                                                            className="btn btn-outline-danger square-btn btn-sm mr-1"
                                                            title="Delete"
                                                        >
                                                            <i className="bi bi-trash" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="6" className="text-center">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive mt-4">
                                <div className="d-flex justify-content-lg-end"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;


// Category.js

