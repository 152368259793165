import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
// Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { updateSubscription,getByIdallOrder } from '../../store/allOrderSlice';

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';


const UpdateSubscription = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [status, setStatus] = useState(false);
    const { data:order } = useSelector((state) => state.allOrder)
    console.log(order) // State for checkbox status
    useEffect(()=>{
        dispatch(getByIdallOrder(id))
    },[id])
    const handleCategory = (values) => {
        if (values.shortDescription.value === '') {
            message.error('Must enter Description');
        } else if (values.duration.value === '') {
            message.error('Must enter duration');
        } else if (values.cost.value === '') {
            message.error('Must enter cost');
        } else if (values.allowedAds.value === '') {
            message.error('Must enter usage limit');
        } else {
            dispatch(updateSubscription(id, {
                name: values.name.value,
                duration: Number(values.duration.value),
                cost: Number(values.cost.value),
                shortDescription: values.shortDescription.value,
                allowedAds: values.allowedAds.value,
                status: status, // Pass status to updateSubscription action
            }));
        }
    };

    const handleCheckboxChange = (e) => {
        setStatus(e.target.checked); // Update status state based on checkbox
    };

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/flash_deal.png"
                            alt=""
                        />
                        Subscription
                    </h2>
                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className=" lang_form" id="en-form">
                                        <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                defaultValue={order?.name}
                                                placeholder="Ex : LUX"
                                                required=""
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="duration"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Duration
                                        </label>
                                        <input
                                            type="number"
                                            id="duration"
                                            name="duration"
                                            defaultValue={order?.duration}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="cost"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Cost
                                        </label>
                                        <input
                                            type="number"
                                            id="cost"
                                            name="cost"
                                            defaultValue={order?.cost}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="shortDescription"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Description
                                        </label>
                                        <textarea
                                            type="text"
                                            name="shortDescription"
                                            className="form-control"
                                            id="shortDescription"
                                            placeholder="Enter description"
                                            required=""
                                            defaultValue={order?.shortDescription}
                                            maxLength='500'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="allowedAds"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Allowed ads
                                        </label>
                                        <input
                                            type="number"
                                            id="allowedAds"
                                            name="allowedAds"
                                            defaultValue={order.allowedAds }
                                            className='form-control'
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Status:
                                        </label>
                                        <input
                                            id='status'
                                            style={{ width: '16px', height: '16px', margin: '5px' }}
                                            type="checkbox"
                                            name="status"
                                            defaultChecked={order.status} // Bind checked attribute to status state
                                            onChange={handleCheckboxChange} // Handle checkbox change
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateSubscription;

