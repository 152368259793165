import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader'; // Assuming you have a Loader component for loading states


const RestaurantCosttable = () => {
    const [cost, setCost] = useState('');
    const [loading, setLoading] = useState(false);
    const [restaurantFees, setRestaurantFees] = useState([]); // Holds all restaurant fee data
    const navigate = useNavigate();

    // Fetch restaurant fee data on component mount
    useEffect(() => {
        const fetchFees = async () => {
            try {
                const response = await axios.get('https://foodserver.ashhkaro.com/common/restaurantFeeBought');
                setRestaurantFees(response.data);
                console.log(response) // Assume API returns an array of fees
            } catch (error) {
                console.error('Error fetching restaurant fees:', error);
                message.error('Failed to load restaurant fees.');
            }
        };

        fetchFees();
    }, []);

    // Handle form submission (creating or updating cost)
   
    // Handle delete functionality
   
  
    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
             

               
               
                    <div className="row mt-3" id="cate-table">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="text-capitalize d-flex gap-1">
                                                Restaurant Fee List
                                                <span className="badge badge-soft-dark radius-50">{restaurantFees.length}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Restaurant</th>
                                                <th>Payment Date </th>
                                                <th >transactionId</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {restaurantFees.length > 0 ? (
                                                restaurantFees.map((item, index) => (
                                                    <tr key={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.restaurantId?.restaurantName}</td>
                                                        <td>{item.paymentDate}</td>
                                                        <td >
                                                            {item.transactionId}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="4" className="text-center">No data available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            
            </div>
        </div>
    );
};

export default RestaurantCosttable;
