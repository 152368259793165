import React, { useEffect, useState } from 'react';
import { Select, message, Spin } from 'antd';
import axios from 'axios';
import Loader from '../Loader';  // Assuming you have a Loader component for loading state

const { Option } = Select;

const WalletWithdrawals = () => {
    const [walletData, setWalletData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchWalletData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://foodserver.ashhkaro.com/common/walletWithdraw');
                setWalletData(response.data);
                console.log(response.data)
            } catch (error) {
                message.error('Failed to fetch wallet withdrawals.');
            } finally {
                setLoading(false);
            }
        };
        fetchWalletData();
    }, []);

    const handleStatusChange = async (id, newStatus) => {
        try {
            await axios.patch(`https://foodserver.ashhkaro.com/common/walletWithdraw/${id}/status`, { status: newStatus });
            // Update the status in the local state to reflect the change
            setWalletData((prevData) =>
                prevData.map((item) => (item._id === id ? { ...item, status: newStatus } : item))
            );
            message.success('Status updated successfully');
        } catch (error) {
            message.error('Failed to update status');
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="mt-5 toppp">
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0 d-flex gap-10">
                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png" alt="" />
                    Wallet Withdrawals
                </h2>
                <div className="row mt-3" id="wallet-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                        <h5 className="text-capitalize d-flex gap-1">
                                            Wallet Withdrawals List
                                            <span className="badge badge-soft-dark radius-50 ">{walletData.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table
                                    id="walletWithdrawTable"
                                    style={{ textAlign: 'left' }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL</th>
                                            <th>Restaurant</th>
                                            <th>Amount</th>
                                            <th>Account Number</th>
                                            <th>Account Title</th>
                                            <th>Bank Name</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {walletData.length > 0 ? (
                                            walletData.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.restaurantId?.restaurantName || 'Unknown'}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.accountNumber}</td>
                                                    <td>{item.accountTitle}</td>
                                                    <td>{item.bankName}</td>
                                                    <td>
                                                        <Select
                                                            defaultValue={item.status}
                                                            onChange={(value) => handleStatusChange(item._id, value)}
                                                            style={{ width: 120 }}
                                                        >
                                                            <Option value="pending">Pending</Option>
                                                            <Option value="approved">Approved</Option>
                                                            <Option value="rejected">Rejected</Option>
                                                        </Select>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WalletWithdrawals;
