import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import Loader from '../Loader';  // Assuming you have a Loader component for loading state

const Expenses = () => {
    const [expensesData, setExpensesData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchExpensesData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://foodserver.ashhkaro.com/common/expense');
                setExpensesData(response.data);
                console.log(response.data);  // Log response data for debugging
            } catch (error) {
                message.error('Failed to fetch expense data.');
            } finally {
                setLoading(false);
            }
        };
        fetchExpensesData();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="mt-5 toppp">
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0 d-flex gap-10">
                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png" alt="" />
                    Expense List
                </h2>
                <div className="row mt-3" id="expense-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                        <h5 className="text-capitalize d-flex gap-1">
                                            Expense List
                                            <span className="badge badge-soft-dark radius-50 ">{expensesData.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table
                                    id="expenseTable"
                                    style={{ textAlign: 'left' }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL</th>
                                            <th>Restaurant</th>
                                            <th>Title</th>
                                            <th>Amount</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {expensesData.length > 0 ? (
                                            expensesData.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.restaurantId?.restaurantName || 'Unknown Restaurant'}</td>
                                                    <td>{item.title || 'No Title'}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.description || 'No Description'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Expenses;
