import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import Loader from '../Loader';
import { message } from 'antd';

const PaymentDisplay = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const response = await axios.get('https://foodserver.ashhkaro.com/common/orderPaymentOnline');
                setPayments(response?.data); // Adjust based on actual data structure
                message.success('Payments fetched successfully!');
            } catch (error) {
                console.error('Error fetching payments:', error);
                message.error('Failed to fetch payments.');
            } finally {
                setLoading(false);
            }
        };

        fetchPayments();
    }, []);

    // Initialize DataTable
    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#paymentTable').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    destroy: true,
                });
            }, 1000);
        });
    }, [payments]);

    // Function to format the date to a readable format
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(date).toLocaleString('en-US', options);
    };

    return (
        <div className='mt-5 toppp'>
            {loading ? <Loader /> : null}
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        Payment Records
                    </h2>
                </div>
                <div className="row" id="payment-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-6 mb-2 mb-md-0">
                                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                                            Payments
                                            <span className="badge badge-soft-dark radius-50 fz-12">{payments.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {payments?.length > 0 ? (
                                <div className="table-responsive">
                                    <table
                                        id="paymentTable"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th className="pl-xl-5">SL</th>
                                                <th>Invoice ID</th>
                                                <th>Amount</th>
                                                <th>Transaction Id</th>
                                                <th>Restaurant</th>
                                                <th>Payment Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments.map((payment, index) => (
                                                <tr key={payment?._id}>
                                                    <td className="pl-xl-5">{index + 1}</td>
                                                    <td>{payment?.invoiceId}</td>
                                                    <td>{payment?.paymentAmount.toFixed(2)}</td>
                                                    <td>{payment?.transactionId}</td>
                                                    <td>{payment?.restaurantId?.restaurantName}</td>
                                                    <td>{formatDate(payment?.paymentDate)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className='text-center'>No payment records available</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentDisplay;
